@tailwind base;
@tailwind components;
@tailwind utilities;

.glass-panel {
  position: relative;
  display: inline-block;
  padding: 20px 60px;
  perspective: 1000px;
}

/* 分离玻璃背景 */
.glass-background {
  position: absolute;
  top: -10px;
  left: -20px;
  right: -20px;
  bottom: -10px;
  background: linear-gradient(
    135deg,
    rgba(176, 224, 255, 0.4),
    rgba(176, 224, 255, 0.2)
  );
  border: 2px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 30px rgba(176, 224, 255, 0.3),
    inset 0 0 20px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  transform-style: preserve-3d;
  animation: glass-break 1.2s ease-out forwards;
  animation-delay: 1.8s; /* 等所有字符出现后再破碎 */
  border-radius: 15px;
  z-index: 1;
}

.animate-character {
  position: relative;
  display: inline-block;
  opacity: 0;
  animation: character-drop-in 1s ease-out forwards;
  animation-delay: var(--delay);
  z-index: 2; /* 确保文字在玻璃之上 */
}

/* 调整英文部分的样式 */
.animate-character:first-child {
  letter-spacing: -0.5px; /* 减小英文字母间距 */
  margin-right: 8px; /* 与中文的间距 */
}

/* 调整中文字符的间距 */
.animate-character:not(:first-child) {
  margin: 0 1px;
}

/* 增加标题和描述文字之间的间距 */
.glass-panel + p {
  margin-top: 2rem; /* 或者使用具体的像素值，比如 32px */
}

@keyframes glass-break {
  0% {
    opacity: 1;
    transform: scale(1) translateZ(0);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  15% {
    transform: scale(1.02) translateZ(0);
  }
  20% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  21% {
    clip-path: polygon(
      0 0,
      33% 0,
      66% 0,
      100% 0,
      100% 33%,
      100% 66%,
      100% 100%,
      66% 100%,
      33% 100%,
      0 100%,
      0 66%,
      0 33%
    );
  }
  25% {
    opacity: 1;
  }
  30% {
    clip-path: polygon(
      15% 0,
      45% 0,
      75% 0,
      100% 15%,
      100% 45%,
      100% 75%,
      85% 100%,
      55% 100%,
      25% 100%,
      0 85%,
      0 55%,
      0 25%
    );
  }
  35% {
    transform: scale(1.1) translateZ(50px) rotate(2deg);
  }
  100% {
    opacity: 0;
    transform: scale(1.3) translateZ(100px) rotate(-5deg);
    clip-path: polygon(
      20% 0,
      50% 0,
      80% 0,
      100% 20%,
      100% 50%,
      100% 80%,
      80% 100%,
      50% 100%,
      20% 100%,
      0 80%,
      0 50%,
      0 20%
    );
  }
}

@keyframes character-drop-in {
  0% {
    opacity: 0;
    transform: translateY(-100px) rotate(15deg);
  }
  70% {
    transform: translateY(0) rotate(-10deg);
  }
  85% {
    transform: translateY(-10px) rotate(8deg);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(0);
  }
}

/* 火苗动画效果 */
.flame {
  width: 12px;
  height: 12px;
  position: absolute;
  transform-origin: center bottom;
  animation: flicker 0.5s infinite alternate;
}

.flame-inner {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    #ff4500,
    /* 底部橙红色 */ #ffa500,
    /* 中间橙色 */ #ffff00 /* 顶部黄色 */
  );
  border-radius: 50% 50% 20% 20%;
  box-shadow: 0 0 10px #ff4500, 0 0 20px #ff4500, 0 0 40px #ffa500;
  transform-origin: center bottom;
  animation: burn 0.5s infinite alternate;
}

@keyframes flicker {
  0% {
    transform: rotate(-2deg) scale(1);
  }
  100% {
    transform: rotate(2deg) scale(1.1);
  }
}

@keyframes burn {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1.1);
  }
}

/* 确保进度条容器有足够空间显示火苗 */
.relative.w-full.bg-gray-200 {
  margin: 4px 0;
  overflow: visible !important;
}

@keyframes flameMove {
  0%,
  100% {
    transform: translateY(-1px) scale(1);
  }
  50% {
    transform: translateY(-2px) scale(1.1);
  }
}

.animate-flame {
  animation: flameMove 0.5s ease-in-out infinite;
  filter: drop-shadow(0 0 2px rgba(255, 69, 0, 0.5));
  z-index: 10;
}

/* 确保进度条容器有足够空间显示火焰 */
.relative.w-full.bg-gray-200 {
  margin: 4px 0;
}

/* 充能时的渐变动画 */
@keyframes gradientShift {
  0% {
    background: linear-gradient(90deg, #ff4d4d, #ff8080);
  }
  50% {
    background: linear-gradient(90deg, #ff6b6b, #ffa07a);
  }
  100% {
    background: linear-gradient(90deg, #ff4d4d, #ff8080);
  }
}

.charging-gradient {
  animation: gradientShift 2s ease infinite;
  background-size: 200% 100%;
}

/* 火焰动画 */
@keyframes flameMove {
  0%,
  100% {
    transform: translateY(-1px) scale(1);
  }
  50% {
    transform: translateY(-2px) scale(1.1);
  }
}

.animate-flame {
  animation: flameMove 0.5s ease-in-out infinite;
  filter: drop-shadow(0 0 2px rgba(255, 69, 0, 0.5));
  z-index: 10;
}

/* 确保进度条容器有足够空间显示火焰 */
.relative.w-full.bg-gray-200 {
  margin: 4px 0;
}
